@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
    font-family: Italianno-Regular;
    font-weight: normal;
    src: url('./fonts/Italianno-Regular.ttf') format('truetype');
} */

@font-face {
    font-family: 'NanumGothic';
    font-weight: normal;
    src:
        url('./fonts/NanumGothic/NanumGothic.ttf') format('truetype'),
        url('./fonts/NanumGothic/NanumGothic.otf') format('opentype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
    font-family: 'NanumGothicBold';
    font-weight: bold;
    src: url('./fonts/NanumGothic/NanumGothicBold.ttf') format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
    font-family: 'NanumGothicExtraBold';
    font-weight: 800;
    src: url('./fonts/NanumGothic/NanumGothicExtraBold.ttf') format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
    font-family: 'NanumGothicLight';
    font-weight: 300;
    src: url('./fonts/NanumGothic/NanumGothicLight.ttf') format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
    font-family: OpenSans-Regular;
    font-weight: normal;
    src: url('./fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
    font-family: OpenSans-Bold;
    font-weight: bold;
    src: url('./fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
    font-family: OpenSans-ExtraBold;
    font-weight: 800;
    src: url('./fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
    font-family: OpenSans-Light;
    font-weight: 300;
    src: url('./fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

html,
body {
    margin: 0;
    font-family: 'NanumGothic', 'NanumGothicBold', 'NanumGothicExtraBold',
        'NanumGothicLight', 'OpenSans-Regular', 'OpenSans-Bold',
        'OpenSans-ExtraBold', 'OpenSans-Light';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.icon {
    width: 200px;
    height: 50px;
    fill: currentColor;
    object-fit: cover;
}
.icon-lg {
    width: 500px;
    height: 125px;
    fill: currentColor;
    object-fit: cover;
}

.home-background {
    margin: 0px auto;
    padding: 70px 30px;
    background: linear-gradient(
        -45deg,
        #23a6d5,
        hsl(196, 72%, 71%),
        #526c6f,
        #19857b
    );
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

.x-axis-label {
    position: absolute; /* 축 레이블을 절대 위치로 설정 */
    bottom: 20px; /* 축 레이블을 그래프 아래로 이동 */
    font-size: 12px; /* 축 레이블 글꼴 크기 조절 */
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
section:nth-child(1) {
    color: #e0ffff;
}
section:nth-child(2) {
    color: #42455a;
    background: #e0ffff;
}
section:nth-child(3) {
    color: #e0ffff;
}
section:nth-child(4) {
    color: #42455a;
    background: #e0ffff;
}
section .container {
    margin: 100px;
}
section h1 {
    font-size: 3rem;
    margin: 20px;
}
section h2 {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
}
section .text-container {
    display: flex;
}
section .text-container .text-box {
    margin: 20px;
    padding: 20px;
    background: #00c2cb;
}
section .text-container .text-box h3 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
}

@media (max-width: 900px) {
    section h1 {
        font-size: 2rem;
        text-align: center;
    }
    section .text-container {
        flex-direction: column;
    }
}

.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}
.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}
.active.fade-left {
    animation: fade-left 1s ease-in;
}
.active.fade-right {
    animation: fade-right 1s ease-in;
}
@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

#add-asset-dialog-title {
    cursor: move;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

#add-asset-dialog-description {
    font-size: 16px;
    color: #0d65a2;
    font-weight: bold;
}

#add-asset-dialog-sub-description {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
}
